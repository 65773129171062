import { User, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { auth } from "./firebase";

export function useCurrentUser() {
  const [user, setUser] = useState<User | null>();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }
  });

  return { user };
}
