import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function createId() {
  return crypto.randomUUID();
}

export const VALID_EMAILS = [
  "jordi.err@gmail.com",
  "alejandra.cifre3@gmail.com",
  "roigramisguillem@gmail.com",
  "marilenramisnadal@gmail.com",
];
