import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAjuphrAgIgmDfnz0J8ztI7fjyOkZa5NGQ",
  authDomain: "ses-regates-70c60.firebaseapp.com",
  projectId: "ses-regates-70c60",
  storageBucket: "ses-regates-70c60.appspot.com",
  messagingSenderId: "240736716220",
  appId: "1:240736716220:web:883806572f43ab16e7d7b9",
};

export const fbApp = initializeApp(firebaseConfig);

export const db = getFirestore(fbApp);
export const auth = getAuth(fbApp);
export const storage = getStorage(fbApp);
