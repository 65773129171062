import { auth } from "@/lib/firebase";
import { useCurrentUser } from "@/lib/hooks";
import { VALID_EMAILS } from "@/lib/utils";
import { signOut } from "firebase/auth";
import { useRouter } from "next/router";
import { PropsWithChildren, useEffect } from "react";

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const router = useRouter();
  const { user } = useCurrentUser();

  useEffect(() => {
    if (!user) {
      if (router.pathname !== "/sign-in") {
        router.push("/sign-in");
      }
    } else {
      if (router.pathname === "/sign-in") {
        router.push("/admin");
      }
      if (!VALID_EMAILS.includes(user.email || "")) {
        alert("No tienes permisos para acceder");
        _signOut();
      }
    }
  }, [user, router]);

  function _signOut() {
    signOut(auth).then(() => {
      router.push("/sign-in");
    });
  }

  return (
    <>
      {children}
      {/* <button onClick={_signOut}>Salir</button> */}
    </>
  );
}
